import React, { Component } from "react"
import Star from "assets/star.svg"

class Overline extends Component {
  render() {
    return (
      <h1 className={`c-hero__overline ${this.props.className || ""}`}>
        <div className="inline-block w-3 mr-4 text-accent">
          <Star />
        </div>
        {this.props.children}
      </h1>
    )
  }
}

export default Overline
