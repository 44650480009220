import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Overline from "components/overline"
import FadeIn from "transitions/fade-in"

const Hero = () => {
  const { wpPage } = useStaticQuery(graphql`
    query NewsListing {
      wpPage(databaseId: { eq: 22177 }) {
        newsPage {
          newsHero {
            overline
            heading
            copy
          }
        }
      }
    }
  `)
  const { overline, heading, copy } = wpPage.newsPage.newsHero

  return (
    <section className="text-center c-hero o-container md:text-left">
      <div className="md:px-7">
        <FadeIn>
          <Overline className="sm:text-left">{overline}</Overline>
          <div className="font-serif mb-9 text-72px">{heading}</div>
          <div
            className="max-w-md mx-auto md:ml-0"
            dangerouslySetInnerHTML={{ __html: copy }}
          ></div>
        </FadeIn>
      </div>
    </section>
  )
}

export default Hero
