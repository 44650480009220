import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Star from "assets/star.svg"
import { format } from "date-fns"
import FadeIn from "transitions/fade-in"

const Grid = () => {
  const { allWpPost } = useStaticQuery(graphql`
    query AllNews {
      allWpPost(sort: { fields: date, order: DESC }) {
        totalCount
        posts: nodes {
          databaseId
          date
          title
          article {
            excerpt
            externalLink
          }
        }
      }
    }
  `)
  const { posts, totalCount } = allWpPost
  const perPage = 7
  const [postsVisible, setPostsVisible] = useState(perPage)

  const handleClick = () => {
    setPostsVisible(postsVisible + perPage)
  }

  return (
    <FadeIn>
      <section className="mt-6 mb-12 sm:my-18 md:my-24 lg:my-28">
        {posts
          .slice(0, postsVisible)
          .map(({ databaseId, title, date, article }) => (
            <article
              key={databaseId}
              className="block news-listing-item text-midnight lg:text-iron hover:text-midnight lg:hover:bg-grey"
            >
              <div className="o-container">
                <div className="grid-cols-12 lg:grid">
                  <div className="col-span-9 col-start-3">
                    <div className="news-listing-item__line">
                      <div className="flex items-center self-start mt-1 mb-5 mr-20 font-semibold uppercase lg:mb-8 lg:mb-0 xl:mr-24">
                        <Star className="mr-3 news-listing-item__star" />
                        <div className="whitespace-pre">
                          {format(new Date(date), "MMM yyyy")}
                        </div>
                      </div>
                      <div>
                        <h3
                          className="mb-4 font-serif text-2xl leading-snug sm:text-3xl"
                          dangerouslySetInnerHTML={{ __html: title }}
                        ></h3>
                        <div
                          className="max-w-3xl mb-5 lg:mb-8 sm:text-17px"
                          dangerouslySetInnerHTML={{ __html: article.excerpt }}
                        ></div>
                        <a
                          href={article.externalLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="news-listing-item__link"
                        >
                          Read more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          ))}

        {postsVisible < totalCount && (
          <div className="my-24 text-center">
            <button
              className={`transition button font-serif button--large button--midnight--outline text-16px`}
              onClick={handleClick}
            >
              Load more
            </button>
          </div>
        )}
      </section>
    </FadeIn>
  )
}

export default Grid
