import React from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import Transition from "components/transition"
import Hero from "components/news/hero"
import Grid from "components/news/grid"

const News = React.memo(({ data: { wpPage } }) => {
  if (!wpPage) return null
  const { seo } = wpPage

  return (
    <Transition>
      <SEO
        seo={seo}
        bodyAttributes={{
          class: "page--home",
        }}
      />
      <Hero />
      <Grid />
    </Transition>
  )
})

export const query = graphql`
  query News {
    wpPage(databaseId: { eq: 22177 }) {
      ...SEO
    }
  }
`

export default News
